import { default as adminasuserCA2tY8XzntMeta } from "/home/bas/app_d204df4d-b106-40f7-a458-a1811cd04cbd/pages/adminasuser.vue?macro=true";
import { default as indexNWdg3ATTY3Meta } from "/home/bas/app_d204df4d-b106-40f7-a458-a1811cd04cbd/pages/index.vue?macro=true";
import { default as mes_45chargesHSx5VJ9CMWMeta } from "/home/bas/app_d204df4d-b106-40f7-a458-a1811cd04cbd/pages/mes-charges.vue?macro=true";
import { default as mes_45consommationsYVUCU7zBvwMeta } from "/home/bas/app_d204df4d-b106-40f7-a458-a1811cd04cbd/pages/mes-consommations.vue?macro=true";
import { default as mes_45documentsHhkEJqKBj5Meta } from "/home/bas/app_d204df4d-b106-40f7-a458-a1811cd04cbd/pages/mes-documents.vue?macro=true";
import { default as mes_45equipementsBhG1dpnhxBMeta } from "/home/bas/app_d204df4d-b106-40f7-a458-a1811cd04cbd/pages/mes-equipements.vue?macro=true";
import { default as mon_45profildojWa15OsJMeta } from "/home/bas/app_d204df4d-b106-40f7-a458-a1811cd04cbd/pages/mon-profil.vue?macro=true";
import { default as mon_45tableau_45de_45bordiwzRXwNTccMeta } from "/home/bas/app_d204df4d-b106-40f7-a458-a1811cd04cbd/pages/mon-tableau-de-bord.vue?macro=true";
import { default as motdepasse_45oublie187tEdwLiIMeta } from "/home/bas/app_d204df4d-b106-40f7-a458-a1811cd04cbd/pages/motdepasse-oublie.vue?macro=true";
import { default as renouveler_45motdepassecnc2JjpcxtMeta } from "/home/bas/app_d204df4d-b106-40f7-a458-a1811cd04cbd/pages/renouveler-motdepasse.vue?macro=true";
export default [
  {
    name: "adminasuser",
    path: "/adminasuser",
    meta: adminasuserCA2tY8XzntMeta || {},
    component: () => import("/home/bas/app_d204df4d-b106-40f7-a458-a1811cd04cbd/pages/adminasuser.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexNWdg3ATTY3Meta || {},
    component: () => import("/home/bas/app_d204df4d-b106-40f7-a458-a1811cd04cbd/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "mes-charges",
    path: "/mes-charges",
    meta: mes_45chargesHSx5VJ9CMWMeta || {},
    component: () => import("/home/bas/app_d204df4d-b106-40f7-a458-a1811cd04cbd/pages/mes-charges.vue").then(m => m.default || m)
  },
  {
    name: "mes-consommations",
    path: "/mes-consommations",
    meta: mes_45consommationsYVUCU7zBvwMeta || {},
    component: () => import("/home/bas/app_d204df4d-b106-40f7-a458-a1811cd04cbd/pages/mes-consommations.vue").then(m => m.default || m)
  },
  {
    name: "mes-documents",
    path: "/mes-documents",
    meta: mes_45documentsHhkEJqKBj5Meta || {},
    component: () => import("/home/bas/app_d204df4d-b106-40f7-a458-a1811cd04cbd/pages/mes-documents.vue").then(m => m.default || m)
  },
  {
    name: "mes-equipements",
    path: "/mes-equipements",
    meta: mes_45equipementsBhG1dpnhxBMeta || {},
    component: () => import("/home/bas/app_d204df4d-b106-40f7-a458-a1811cd04cbd/pages/mes-equipements.vue").then(m => m.default || m)
  },
  {
    name: "mon-profil",
    path: "/mon-profil",
    meta: mon_45profildojWa15OsJMeta || {},
    component: () => import("/home/bas/app_d204df4d-b106-40f7-a458-a1811cd04cbd/pages/mon-profil.vue").then(m => m.default || m)
  },
  {
    name: "mon-tableau-de-bord",
    path: "/mon-tableau-de-bord",
    meta: mon_45tableau_45de_45bordiwzRXwNTccMeta || {},
    component: () => import("/home/bas/app_d204df4d-b106-40f7-a458-a1811cd04cbd/pages/mon-tableau-de-bord.vue").then(m => m.default || m)
  },
  {
    name: "motdepasse-oublie",
    path: "/motdepasse-oublie",
    meta: motdepasse_45oublie187tEdwLiIMeta || {},
    component: () => import("/home/bas/app_d204df4d-b106-40f7-a458-a1811cd04cbd/pages/motdepasse-oublie.vue").then(m => m.default || m)
  },
  {
    name: "renouveler-motdepasse",
    path: "/renouveler-motdepasse",
    meta: renouveler_45motdepassecnc2JjpcxtMeta || {},
    component: () => import("/home/bas/app_d204df4d-b106-40f7-a458-a1811cd04cbd/pages/renouveler-motdepasse.vue").then(m => m.default || m)
  }
]